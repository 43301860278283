// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {OMLgmyHqs: {hover: true}, u0lGUsMEN: {hover: true}};

const cycleOrder = ["u0lGUsMEN", "OMLgmyHqs"];

const variantClassNames = {OMLgmyHqs: "framer-v-175a0pc", u0lGUsMEN: "framer-v-1qnu00k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Dark: "OMLgmyHqs", Light: "u0lGUsMEN"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.12, 0.23, 0.5, 1], mass: 1, stiffness: 500, type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; icon?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "u0lGUsMEN", link: J8tIMGt15, icon: NdiriCA9_ = {src: new URL("assets/6yyZFRk9LE4NSM2xDBhFlzvndB4.svg", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "u0lGUsMEN", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fviu2", classNames)} style={{display: "contents"}}>
<Link href={J8tIMGt15}><motion.a {...restProps} className={`${cx("framer-1qnu00k", className)} framer-odf7ch`} data-border data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"u0lGUsMEN"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4ba96c1e-b647-438d-9d5c-3ff29696d43f, rgb(235, 236, 239)) /* {\"name\":\"Neutral 200\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(246, 247, 248, 0)", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} transition={transition} variants={{"OMLgmyHqs-hover": {"--border-color": "rgba(255, 255, 255, 0.16)", backgroundColor: "rgba(255, 255, 255, 0.12)"}, "u0lGUsMEN-hover": {"--border-color": "var(--token-52f4691f-4685-4197-b466-d82475c77641, rgb(246, 247, 248)) /* {\"name\":\"Neutral 100\"} */", backgroundColor: "var(--token-52f4691f-4685-4197-b466-d82475c77641, rgb(246, 247, 248))"}, OMLgmyHqs: {"--border-color": "rgba(255, 255, 255, 0.08)", backgroundColor: "rgba(255, 255, 255, 0.06)"}}} {...addPropertyOverrides({"OMLgmyHqs-hover": {"data-framer-name": undefined}, "u0lGUsMEN-hover": {"data-framer-name": undefined}, OMLgmyHqs: {"data-framer-name": "Dark"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 24, intrinsicWidth: 24, pixelHeight: 24, pixelWidth: 24, sizes: "24px", ...toResponsiveImage(NdiriCA9_)}} className={"framer-z2sqs0"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"yU2BtZz7v"} transition={transition}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fviu2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fviu2 .framer-odf7ch { display: block; }", ".framer-fviu2 .framer-1qnu00k { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 8px 8px 8px 8px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-fviu2 .framer-z2sqs0 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: hidden; position: relative; width: 24px; }", ".framer-fviu2 .framer-v-1qnu00k .framer-1qnu00k, .framer-fviu2 .framer-v-175a0pc .framer-1qnu00k { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fviu2 .framer-1qnu00k { gap: 0px; } .framer-fviu2 .framer-1qnu00k > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-fviu2 .framer-1qnu00k > :first-child { margin-left: 0px; } .framer-fviu2 .framer-1qnu00k > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OMLgmyHqs":{"layout":["auto","auto"]},"qq8SrjnGM":{"layout":["auto","auto"]},"ewSyoS3TK":{"layout":["auto","auto"]}}}
 * @framerVariables {"J8tIMGt15":"link","NdiriCA9_":"icon"}
 */
const FramergTVKRbrb7: React.ComponentType<Props> = withCSS(Component, css, "framer-fviu2") as typeof Component;
export default FramergTVKRbrb7;

FramergTVKRbrb7.displayName = "Button/Social Link";

FramergTVKRbrb7.defaultProps = {height: 40, width: 40};

addPropertyControls(FramergTVKRbrb7, {variant: {options: ["u0lGUsMEN", "OMLgmyHqs"], optionTitles: ["Light", "Dark"], title: "Variant", type: ControlType.Enum}, J8tIMGt15: {title: "Link", type: ControlType.Link}, NdiriCA9_: {__defaultAssetReference: "data:framer/asset-reference,6yyZFRk9LE4NSM2xDBhFlzvndB4.svg?originalFilename=Social+Facebook.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramergTVKRbrb7, [])